import { useState } from "react";

import CancelIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton, useMediaQuery } from "@mui/material";

import NavItem from "@/comps/NavItem";
import SkinnySection from "@/comps/SkinnySection";
import Irrigation from "@/modals/irrigation/Irrigation";
import Landscaping from "@/modals/landscaping/Landscaping";
import Lighting from "@/modals/lighting/Lighting";
import SnowManagement from "@/modals/snowManagement/SnowManagement";
import Wash from "@/modals/wash/Wash";
import theme from "@/theme";

const services = [
  {
    name: "Snow/Ice Management",
    comp: <SnowManagement />,
  },
  {
    name: "Landscaping",
    comp: <Landscaping />,
  },
  {
    name: "Irrigation",
    comp: <Irrigation />,
  },
  {
    name: "Lighting",
    comp: <Lighting />,
  },
  {
    name: "Wash Services",
    comp: <Wash />,
  },
];

function Commercial() {
  const [modalId, setModalId] = useState("");
  const fullScreen = useMediaQuery(theme.material.breakpoints.down("md"));

  return (
    <SkinnySection sx={{ background: theme.darkSnowBackground }}>
      {services.map(item => (
        <Box key={item.name}>
          <Button className="service" onClick={() => setModalId(item.name)}>
            {item.name}
          </Button>
          <Dialog
            disableScrollLock={true}
            open={modalId === item.name}
            onClose={() => setTimeout(() => setModalId(""), 0)}
            scroll="body"
            fullScreen={fullScreen}
            sx={{
              "& .MuiDialog-paper": {
                height: "unset",
                width: "40rem",
                maxWidth: "100%",
                marginTop: "5.5rem !important",
                borderRadius: "1rem",
              },
            }}
          >
            <>
              <IconButton
                onClick={() => setTimeout(() => setModalId(""), 0)}
                sx={{
                  fontSize: "3rem",
                  padding: 0,
                  position: "absolute",
                  top: { xs: "1rem", md: "2rem" },
                  right: { xs: "1rem", md: "2rem" },
                  color: "white",
                }}
              >
                <CancelIcon fontSize="inherit" />
              </IconButton>
              {item.comp}
            </>
          </Dialog>
        </Box>
      ))}
      <br />

      <Box>
        <NavItem href="/contact?lob=commercial">
          <Button variant="contained" size="large" sx={{ fontSize: "2rem" }}>
            Request Commercial Services
          </Button>
        </NavItem>
      </Box>
    </SkinnySection>
  );
}

export default Commercial;
