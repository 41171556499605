import { Stack } from "@mui/material";

/**
 * XS: Vertical
 *
 * MD: Horizontal
 *
 * @param {import("@mui/material").StackProps} p
 */
function PivotStack(p) {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: "2rem", md: "5rem" }}
      alignItems={{ xs: undefined, md: "center" }}
      justifyContent={{ xs: "center", md: "left" }}
      padding="2rem 0"
      {...p}
    >
      {p.children}
    </Stack>
  );
}

export default PivotStack;
