import { Stack, Typography } from "@mui/material";

import ServiceDetail from "@/modals/serviceDetail/ServiceDetail";

function SnowManagement() {
  return (
    <ServiceDetail
      title="Snow/Ice Management"
      description={
        <>
          <Typography>
            WMV Enterprises is here to provide the best quality Snow/Ice Management services in town.
          </Typography>
          <Stack component="ul" spacing="1rem">
            <li>
              Snow Hauling - We want you to be able to go back to normal business as soon as possible without letting a
              little snow get in the way. We will be there for you to remove any snow or ice that is in the way.
            </li>
            <li>
              Salting - Salting is an essential step in preparing for a snowstorm. It can be a tedious and strenuous
              process, but it is important for preventing black ice and assuring your safety. We have the best equipment
              and team that will make the salting process faster and more efficient.
            </li>
            <li>
              Parking Deck Clearing - We have the manpower and equipment for any type of snow shoveling. No job is too
              big for us to handle.
            </li>
            <li>
              Snow Plowing - Our snow plowing equipment is the best in the business. We can clear any roads or other
              motorways swiftly and effectively.
            </li>
            <li>
              Sidewalk Clearing - No matter how large or small, we have the manpower and equipment to safely remove snow
              or ice on all types of sidewalks or walkways. We use a variety of specialized machines and hand crews that
              easily clear your buildings' paths, so they don't become hazardous to you and the public.
            </li>
            <li>
              Client Specific Work - Do you have a specific job you need help with? Give us a call and we will do our
              best to help you out! Our equipment range is substantial, and our team is knowledgeable. Contact us today!
            </li>
          </Stack>
        </>
      }
    />
  );
}

export default SnowManagement;
