import { Box } from "@mui/material";

import theme from "@/theme";

/** @param {import("@mui/material").BoxProps} p */
function TallSection(p) {
  return (
    <Box bgcolor="white" minHeight={theme.fullHeight} {...p}>
      {p.children}
    </Box>
  );
}

export default TallSection;
