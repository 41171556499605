import React from "react";

import ReactDOM from "react-dom/client";

import env from "@/env";

import App from "./shell/App";

function main() {
  const rootEl = document.getElementById("root");

  if (!rootEl) {
    console.error("Element #root is required");
    return;
  }

  const root = ReactDOM.createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );

  window.env = env;
}

main();
