import { useLocation, useNavigate } from "react-router-dom";

import { Link } from "@mui/material";

import theme from "@/theme";

/** @param {INavItem} p */
function NavItem(p) {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = p.href && location.pathname.startsWith(p.href);

  return (
    <Link
      underline="none"
      width="100%"
      onClick={e => {
        e.preventDefault();

        if (p.href) {
          navigate(p.href);
        }
      }}
      {...p}
      sx={{
        color: isActive ? theme.primary : theme.textColor,
        fontWeight: isActive ? "bold" : undefined,

        "&:hover": {
          color: theme.primary,
          fontWeight: "bold",
        },
        ...p.sx,
      }}
    >
      {p.children}
    </Link>
  );
}

export default NavItem;
