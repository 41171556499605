import { useState } from "react";

import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";

const services = ["Irrigation", "Landscape Lighting", "Soft/Power Wash"];

/**
 * @param {Object} p
 * @param {(x: string[]) => any} p.onChange
 */
function Residential(p) {
  const [selectedServices, setSelectedServices] = useState(/** @type {string[]} */ ([]));

  return (
    <>
      <Box>
        <Typography component="span" fontWeight="bold">
          Residential
        </Typography>
        <Typography component="span"> (Select all that apply)</Typography>
      </Box>
      <FormGroup>
        {services.map(item => (
          <FormControlLabel
            key={item}
            control={
              <Checkbox
                checked={selectedServices.includes(item)}
                onChange={e => {
                  let _new;

                  if (e.target.checked) {
                    _new = [...selectedServices, item];
                  } else {
                    _new = [...selectedServices.filter(x => x !== item)];
                  }

                  setSelectedServices(_new);
                  p.onChange(_new);
                }}
              />
            }
            label={item}
          />
        ))}
      </FormGroup>
    </>
  );
}

export default Residential;
