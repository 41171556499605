import { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Container, Drawer, IconButton, List, ListItem, Stack } from "@mui/material";

import NavItem from "@/comps/NavItem";
import logo from "@/images/logo.png";
import logo2 from "@/images/logo2.png";
import theme from "@/theme";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box component="header" maxWidth={theme.maxWidth} position="sticky" top={0} zIndex={10_000}>
      <AppBar component="nav" sx={{ backgroundColor: "white", position: "static" }}>
        <Box sx={{ padding: ".75rem 0" }}>
          <Container>
            <Stack direction="row" spacing=".5rem" justifyContent="space-between" width="100%">
              <Stack direction="row" spacing="2rem">
                <NavItem href="/" padding={0} width="unset" display="flex">
                  <Box component="img" src={logo} alt="WMV Enterprises logo" height={{ xs: "3rem", md: "4rem" }} />
                </NavItem>

                <NavItem href="/" padding={0} width="unset" display="flex">
                  <Box
                    component="img"
                    src={logo2}
                    alt="WMV Facility Services logo"
                    height={{ xs: "3rem", md: "4rem" }}
                  />
                </NavItem>
              </Stack>

              <Stack
                direction="row"
                spacing="3rem"
                alignItems="center"
                display={{ xs: "none", md: "flex" }}
                sx={{ "& *": { whiteSpace: "nowrap" } }}
              >
                <NavItem href="/about">About Us</NavItem>
                <Box sx={{ "&:hover .drop": { visibility: "visible" } }}>
                  <NavItem href="/services">Our Services</NavItem>
                  <Box position="relative">
                    <Box className="drop" position="absolute" left="-2rem" visibility="collapse">
                      <Box height="1rem" />
                      <List
                        sx={{
                          background: "white",
                          boxShadow: theme.boxShadow,
                          "& li": { padding: "1rem 2rem" },
                        }}
                      >
                        <ListItem>
                          <NavItem href="/services/commercial">Commercial</NavItem>
                        </ListItem>
                        <ListItem>
                          <NavItem href="/services/residential">Residential</NavItem>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
                <NavItem href="/join">Join Our Team</NavItem>
                <NavItem href="/contact">
                  <Button variant="outlined">Contact Us</Button>
                </NavItem>
              </Stack>

              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={() => setDrawerOpen(x => !x)}
                sx={{ color: theme.textColor, mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          </Container>
        </Box>
      </AppBar>
      <Drawer
        variant="temporary"
        disableScrollLock={true}
        color={theme.textColor}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          display: { xs: "block", md: "none" },
          zIndex: 9_000,
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "100vw" },
        }}
      >
        <Box
          onClick={() => setDrawerOpen(false)}
          marginTop="5.5rem"
          paddingTop="2rem"
          sx={{
            "& li": { textAlign: "center" },
            "& > ul > li": { fontSize: "1.5rem", fontWeight: "bold" },
            "& ul > ul": { padding: 0 },
            "& ul > ul > li": { padding: ".25rem 0" },
          }}
        >
          <List>
            <ListItem>
              <NavItem href="/about">About Us</NavItem>
            </ListItem>
            <ListItem>
              <NavItem href="/services">Our Services</NavItem>
            </ListItem>
            <List>
              <ListItem>
                <NavItem href="/services/commercial">Commercial</NavItem>
              </ListItem>
              <ListItem>
                <NavItem href="/services/residential">Residential</NavItem>
              </ListItem>
            </List>
            <ListItem>
              <NavItem href="/join">Join Our Team</NavItem>
            </ListItem>
            <ListItem>
              <NavItem href="/contact">
                <Button variant="outlined">Contact Us</Button>
              </NavItem>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Header;
