import { useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import SkinnySection from "@/comps/SkinnySection";

import backgroundImg from "./background.jpg";
import Commercial from "./comps/Commercial";
import ContactInfo from "./comps/ContactInfo";
import Residential from "./comps/Residential";

function Contact() {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [lob, setLob] = useState(params.get("lob") ?? "");

  const [services, setServices] = useState(/** @type {string[]} */ ([]));

  const [projectDescription, setProjectDescription] = useState("");

  const [contactInfo, setContactInfo] = useState(/** @type {IContactInfo} */ ({}));

  const showProjectDescription = !!services.length;
  const showContactInfo = !!projectDescription;
  const showSubmitButton = !!projectDescription;

  return (
    <SkinnySection sx={{ background: `url(${backgroundImg}) center / cover no-repeat` }}>
      <Stack
        component="form"
        spacing="1rem"
        onSubmit={e => {
          e.preventDefault();

          /** @type {IContactPayload} */
          const data = {
            q4_projectType: lob[0].toLocaleUpperCase() + lob.slice(1),
            q5_services: services,
            q7_projectDescription: projectDescription,
            q8_companyName: contactInfo.companyName,
            q9_contactName: contactInfo.contactName,
            q10_streetAddress: contactInfo.streetAddress,
            q11_city: contactInfo.city,
            q12_state: contactInfo.state,
            q13_zip: contactInfo.zip,
            q14_email: contactInfo.email,
            q15_phone: contactInfo.phone,
          };

          const formData = new FormData();

          formData.append("formID", "222997800022050");

          for (const key in data) {
            const value = data[key];

            if (Array.isArray(value)) {
              for (const item of value) {
                formData.append(`${key}[]`, item);
              }
            } else {
              formData.append(key, value);
            }
          }

          fetch("https://submit.jotform.com/submit/222997800022050", { method: "POST", body: formData })
            .then(res => {
              if (!res.ok) {
                alert("Uh oh. Something went wrong. Please try again.");

                return;
              }

              navigate("/thank-you");
            })
            .catch(err => {
              console.error(err);

              alert("Uh oh. Something went wrong. Please try again.");
            });
        }}
      >
        <Typography variant="h2">What service can we help you with?</Typography>
        <br />

        <Typography fontWeight="bold">Project Type</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={lob === "commercial"}
                onChange={e => {
                  if (e.target.checked) {
                    setLob("commercial");
                  } else {
                    setLob("");
                  }
                }}
              />
            }
            label="Commercial"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={lob === "residential"}
                onChange={e => {
                  if (e.target.checked) {
                    setLob("residential");
                  } else {
                    setLob("");
                  }
                }}
              />
            }
            label="Residential"
          />
        </FormGroup>
        <br />

        <Fade in={lob === "commercial"} mountOnEnter unmountOnExit>
          <Stack spacing="inherit">
            <Commercial onChange={setServices} />
          </Stack>
        </Fade>

        <Fade in={lob === "residential"} mountOnEnter unmountOnExit>
          <Stack spacing="inherit">
            <Residential onChange={setServices} />
          </Stack>
        </Fade>

        <Fade in={showProjectDescription} mountOnEnter unmountOnExit>
          <Stack spacing="inherit">
            <Typography fontWeight="bold">Project Description</Typography>
            <Box component="textarea" rows={8} onChange={e => setProjectDescription(e.target.value)} />
            <br />
          </Stack>
        </Fade>

        <Fade in={showContactInfo} mountOnEnter unmountOnExit>
          <Stack spacing="inherit">
            <ContactInfo onChange={setContactInfo} />
            <br />
          </Stack>
        </Fade>

        <Fade in={showSubmitButton} mountOnEnter unmountOnExit>
          <Box>
            <Button type="submit" variant="contained" size="large">
              Submit
            </Button>
          </Box>
        </Fade>
      </Stack>
    </SkinnySection>
  );
}

export default Contact;
