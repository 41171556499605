import { createTheme } from "@mui/material/styles";

import darkLandscapingBackgroundImg from "@/images/darkLandscapingBackground.jpg";
import darkSnowBackgroundImg from "@/images/darkSnowBackground.jpg";

export const simple = {
  bgColor: "#252423",
  basic: {
    fontFamily: "Source Serif Pro",
  },
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  boxShadowOnHover: "0px 4px 4px rgba(0, 0, 0, 0.5)",
  darkBrown: "rgba(93, 64, 55, 0.87)",
  darkBrownBoxShadow: `0px 4px 4px rgba(93, 64, 55, 0.25)`,
  darkLandscapingBackground: `url(${darkLandscapingBackgroundImg}) center / cover no-repeat`,
  darkGreen: "rgba(16, 43, 4, 0.25)",
  darkSnowBackground: `url("${darkSnowBackgroundImg}") top right / cover`,
  fullHeight: "calc(100vh - 5.5rem)",
  header: {
    fontFamily: "Oswald",
  },
  lightBoxShadow: "0px 0px 2px 1px rgba(255, 255, 255, 0.87)",
  lightBrown: "rgba(188, 170, 164, 0.87)",
  lightGradient: "linear-gradient(to left, rgba(47, 142, 57, 0.5), rgba(222, 236, 221, 0.87))",
  lightGreen: "rgba(163,176,55, 0.95)",
  lightText: "rgba(255, 255, 255, 0.87)",
  lightTextShadow: "rgb(255 255 255 / 87%) 1px 1px",
  maxWidth: 2880,
  primary: "rgb(201, 32, 38)",
  primaryText: "rgba(201, 32, 38, 0.87)",
  textColor: "rgba(0, 0, 0, 0.87)",
  textShadow: "rgb(0 0 0 / 40%) 1px 1px",
};

const material = createTheme({
  palette: {
    primary: {
      main: simple.primary,
    },
  },
  typography: {
    allVariants: {
      fontFamily: simple.basic.fontFamily,
    },
    body1: {
      fontFamily: simple.basic.fontFamily,
    },
    h1: {
      fontFamily: simple.header.fontFamily,
      fontSize: "3rem",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: simple.header.fontFamily,
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: simple.header.fontFamily,
      fontSize: "1.875rem",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: simple.header.fontFamily,
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1440px !important",
        },
      },
    },
  },
});

const theme = { ...simple, material };

export default theme;
