import { Typography } from "@mui/material";

import SkinnySection from "@/comps/SkinnySection";

import backgroundImg from "./background.jpg";

function About() {
  return (
    <SkinnySection sx={{ background: `url(${backgroundImg}) center / cover no-repeat` }}>
      <Typography variant="h2">About WMV Enterprises LLC</Typography>
      <Typography>
        WMV Enterprises has over 30 years of Snow &amp; Ice Management, Irrigation Management, Landscape Lighting,
        Power/Soft Washing, and Landscape Management Services for some of the largest commercial facilities in the
        Midwest.
      </Typography>
      <Typography>
        We take the worry out of facility management which gives our clients peace of mind. We pride ourselves on our
        ability to successfully meet and exceed facility management expectations in a timely manner all while keeping
        within your budget constraints, maintaining quality and above all, providing safety.
      </Typography>
      <Typography>
        WMV Enterprises uses and maintains our own equipment, ensuring we are ready when a need arises. Our seasoned and
        highly trained team will go above and beyond to make sure the job is done to your satisfaction safely.
      </Typography>
    </SkinnySection>
  );
}

export default About;
