import { Box, Typography } from "@mui/material";

import Img from "@/comps/Img";

import ServiceDetail from "../serviceDetail/ServiceDetail";
import img0 from "./0.jpg";
import img1 from "./1.jpg";

function Wash() {
  return (
    <ServiceDetail
      title="Soft Wash"
      description={
        <>
          <Typography>
            This industry-approved approach engages low-pressure equipment (uses 300psi or less) that sprays water and
            cleaner at a gentle force that can reach out to 60 ft from the ground, with no physical damage to surfaces.
            Soft Wash sanitizes the surface and completely removes all bacteria and infestations and provides a much
            deeper long-lasting clean. Soft Wash is the most effective way to restore your business or home's exterior
            back to a "like new" state so you can protect your investment and the value of your real estate.
          </Typography>

          <Typography>We provide services for:</Typography>

          <Box component="ul">
            <li>Residential Homes &amp; Commercial Buildings</li>
            <li>Sidewalk &amp; Walkway Cleaning</li>
            <li>Patios &amp; Decks</li>
            <li>Roofs</li>
            <li>Parking lot cleaning</li>
            <li>Driveway cleaning</li>
            <li>Drive thru cleaning</li>
            <li>Garage cleaning</li>
          </Box>
        </>
      }
      images={
        <>
          <Img src={img0} alt="sidewalk" sx={{ aspectRatio: "1 / 1" }} />
          <Img src={img1} alt="lawn" sx={{ aspectRatio: "1 / 1" }} />
        </>
      }
    />
  );
}

export default Wash;
