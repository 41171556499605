import { Box, Button, Typography } from "@mui/material";

import SkinnySection from "@/comps/SkinnySection";
import jotform from "@/jotform";

import backgroundImg from "./background.jpg";

function Join() {
  return (
    <SkinnySection sx={{ background: `url(${backgroundImg}) center / cover no-repeat` }}>
      <Typography variant="h2">Join Our Team</Typography>
      <br />

      <Typography variant="h4">Earn more with WMV Enterprises LLC!</Typography>
      <Typography>
        WMV Enterprises LLC is hiring operators, salt and plow truck drivers and laborers for crews in the Metro Area.
      </Typography>
      <br />

      <Typography variant="h4">Why should you join our team?</Typography>
      <Typography>
        WMV Enterprises LLC is the leading provider of snow removal and landscaping services throughout the Midwest and
        we are proud of the team we have put together. We are always looking for dedicated team members to join our
        crew.
      </Typography>
      <ul>
        <li>Family-owned business</li>
        <li>Quick pay!</li>
        <li>Competitive rates</li>
        <li>Quality Accounts</li>
        <li>Highly ranked employee satisfaction</li>
      </ul>
      <br />

      <Typography variant="h2">Employee Forms</Typography>
      <br />

      <Box>
        <Button variant="contained" size="large" onClick={() => jotform.openNewEmployeeApplication()}>
          New Employee Application Form
        </Button>
      </Box>
      <Box>
        <Button variant="contained" size="large" onClick={() => jotform.openWinterEmployeeAgreement()}>
          Winter Employee Agreement Form
        </Button>
      </Box>
    </SkinnySection>
  );
}

export default Join;
