import "@fontsource/ibm-plex-sans";
import "@fontsource/oswald";
import "@fontsource/source-serif-pro";
import "react-image-gallery/styles/css/image-gallery.css";

import { BrowserRouter as Router } from "react-router-dom";

import { Box, CssBaseline, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import useScrollEffect from "@/hooks/useScrollEffect";
import theme from "@/theme";

import Footer from "./Footer";
import Header from "./Header";
import Routes from "./Routes";

function RouteEffects() {
  useScrollEffect();

  return null;
}

function App() {
  return (
    <ThemeProvider theme={theme.material}>
      <CssBaseline />

      <Box bgcolor={theme.bgColor} fontFamily={theme.basic.fontFamily}>
        <Box margin="0 auto" maxWidth={theme.maxWidth}>
          <Router>
            <RouteEffects />
            <Header />
            <Stack direction="column" minHeight="100vh">
              <Box component="main" flex="1 1 0">
                <Routes />
              </Box>
              <Footer />
            </Stack>
          </Router>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
