import { Box, Stack, Typography } from "@mui/material";

import SkinnySection from "@/comps/SkinnySection";

import img0 from "./0.png";
import backgroundImg from "./background.jpg";

function ThankYou() {
  return (
    <SkinnySection textAlign="center" sx={{ background: `url(${backgroundImg}) center / cover no-repeat` }}>
      <Stack direction="row" justifyContent="center">
        <Box component="img" src={img0} alt="thank you icon" width="10rem" maxWidth="100%" />
      </Stack>
      <br />
      <Typography variant="h1">Thank You!</Typography>
      <Typography>Your submission has been received.</Typography>
    </SkinnySection>
  );
}

export default ThankYou;
