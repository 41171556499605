import { Typography } from "@mui/material";

import Img from "@/comps/Img";
import ServiceDetail from "@/modals/serviceDetail/ServiceDetail";

import img0 from "./0.jpg";
import img1 from "./1.png";

function Lighting() {
  return (
    <ServiceDetail
      title="Landscape Lighting"
      description={
        <>
          <Typography>
            We are experts in Landscape Lighting. Imagine using your patio or deck to entertain in the evening. Or bring
            out the architectural features of your house and landscape while creating a safer entry in the process.
            Whatever you can imagine, we can handle. We can show you many options and deliver solutions that exceed your
            expectations.
          </Typography>
          <Typography>
            We will meet with you to go over what you are looking for. We can design, install, and repair landscape
            lighting for residential and commercial applications. We use the best LED lights backed by a 15-year
            warranty.
          </Typography>
        </>
      }
      images={
        <>
          <Img src={img0} alt="lighting" />
          <Img src={img1} alt="lighting award" boxShadow={undefined} sx={{ objectFit: "contain" }} />
        </>
      }
    />
  );
}

export default Lighting;
