import { Box, Container, Typography } from "@mui/material";

import PivotStack from "@/comps/PivotStack";
import logo from "@/images/logo.png";
import theme from "@/theme";

function Footer() {
  return (
    <Box component="footer" bgcolor="black">
      <Container>
        <PivotStack
          alignItems="top"
          justifyContent="space-between"
          color={theme.lightText}
          padding="3rem 0"
          textAlign="center"
        >
          <Box>
            <Typography variant="h4" marginBottom=".25rem">
              Contact Us
            </Typography>
            <Typography>
              2105 S Old Highway 94
              <br />
              St. Charles, MO 63303
              <br />
              636-500-2021
            </Typography>
          </Box>
          <Box>
            <Box
              component="img"
              src={logo}
              alt="WMV Enterprises logo"
              width="20rem"
              maxWidth="100%"
              marginBottom="1rem"
            />
            <Typography>All Rights Reserved | WMV Enterprises © 2022</Typography>
          </Box>
          <Box>
            <Typography variant="h4" marginBottom=".25rem">
              Locations
            </Typography>
            <Typography>Chicago Suburbs</Typography>
            <Typography>St Louis Metro</Typography>
          </Box>
        </PivotStack>
      </Container>
    </Box>
  );
}

export default Footer;
