import { Stack } from "@mui/material";

import useIsMobile from "@/hooks/useIsMobile";

import TallContainer from "./TallContainer";
import TallSection from "./TallSection";

/** @param {import("@mui/system").BoxProps} p */
function SkinnySection(p) {
  const isMobile = useIsMobile();

  return (
    <TallSection {...p}>
      <TallContainer disableGutters={isMobile}>
        <Stack direction="row" minHeight="inherit" padding="5rem 0">
          <Stack
            spacing="1rem"
            padding="2rem"
            bgcolor="white"
            width="45rem"
            maxWidth="100%"
            sx={{
              "& button.service": {
                color: "inherit",
                fontSize: "2rem",
                justifyContent: "left",
                textAlign: "left",
                textTransform: "unset",
              },
            }}
          >
            {p.children}
          </Stack>
        </Stack>
      </TallContainer>
    </TallSection>
  );
}

export default SkinnySection;
