function openNewEmployeeApplication() {
  const height = window.screen.height;
  const width = 1000;

  const left = (window.screen.width - width) / 2;

  window.open(
    `https://form.jotform.com/222794632675062`,
    "blank",
    `scrollbars=yes, toolbar=no, location=no, menubar=no, width=${width}, height=${height}, left=${left}`,
  );
}

function openWinterEmployeeAgreement() {
  const height = window.screen.height;
  const width = 1000;

  const left = (window.screen.width - width) / 2;

  window.open(
    `https://form.jotform.com/222823372517051`,
    "blank",
    `scrollbars=yes, toolbar=no, location=no, menubar=no, width=${width}, height=${height}, left=${left}`,
  );
}

const jotform = { openNewEmployeeApplication, openWinterEmployeeAgreement };

export default jotform;
