import { Box, Stack, Typography } from "@mui/material";

import CssGrid from "@/comps/CssGrid";
import theme from "@/theme";

/** @param {IServiceDetail} p */
function ServiceDetail(p) {
  let { title, description, images, ...innerP } = p;

  if (typeof title === "string") {
    title = (
      <Typography variant="h2" color={theme.lightText} sx={{ textShadow: theme.textShadow }}>
        {title}
      </Typography>
    );
  }

  if (typeof description === "string") {
    description = <Typography>{description}</Typography>;
  }

  return (
    <Box bgcolor="black">
      <Stack
        padding={{ xs: "1rem", md: "2rem" }}
        spacing={{ xs: "1rem", md: "2rem" }}
        color={theme.lightText}
        {...innerP}
        sx={{ background: "#535a62", ...innerP.sx }}
      >
        {title}
        {description}
        {!!images && (
          <CssGrid gap={{ xs: "1rem", md: "2rem" }} itemWidth="15rem">
            {images}
          </CssGrid>
        )}
      </Stack>
    </Box>
  );
}

export default ServiceDetail;
