import { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";

import useIsMobile from "@/hooks/useIsMobile";
import theme from "@/theme";

import mobileImg from "./mobile/0.jpg";

const images = require.context("./desktop", true, /.jpg$/);

const imagesLen = images.keys().length;

function Home() {
  const isMobile = useIsMobile();

  const [imageId, setImageId] = useState(0);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const intervalId = setInterval(() => setImageId(x => (x + 1) % imagesLen), 5_000);

    return () => clearInterval(intervalId);
  }, [isMobile]);

  const img = isMobile ? mobileImg : images(`./${imageId}.jpg`);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      height={theme.fullHeight}
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Typography
        variant="h2"
        color={theme.lightText}
        fontFamily={theme.basic.fontFamily}
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight="unset"
        lineHeight="1.5"
        textAlign="center"
        width="80vh"
        maxWidth="90%"
        sx={{ textShadow: theme.textShadow }}
      >
        We are a forward-thinking company that utilizes and welcomes new technology, new science, and creative solutions
        to exceed client expectations.
      </Typography>
    </Stack>
  );
}

export default Home;
