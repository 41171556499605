import { Typography } from "@mui/material";

import Img from "@/comps/Img";

import ServiceDetail from "../serviceDetail/ServiceDetail";
import img0 from "./0.jpg";
import img1 from "./1.jpg";

function Irrigation() {
  return (
    <ServiceDetail
      title="Lawn Irrigation"
      description={
        <>
          <Typography>WMV Enterprises is here to provide the best quality lawn irrigation services in town.</Typography>
          <Typography>
            Whether you need to repair your existing sprinkler system or get a brand new system. We have YOU covered.
            Fill out the free quote request form below to learn more.
          </Typography>
          <ul>
            <li>Irrigation Install</li>
            <li>Start Up</li>
            <li>Inspections</li>
            <li>Audits</li>
            <li>Backflow testing &amp; repairs</li>
            <li>Repairs</li>
            <li>Winterizations</li>
          </ul>
          <Typography>
            As the grass starts to green up we at WMV Enterprises will be out starting up and running through your
            irrigation systems to prepare them for the up coming season. This is a time when we often discover repair
            needs and can identify upgrade opportunities for your irrigation system.
          </Typography>
          <Typography>
            Irrigation systems are exposed to all sorts of common wear and tear, and most components have a life span of
            5 years. With proper maintenance and winterization, you can expect some heads and valves to need replacement
            each spring.
          </Typography>
          <Typography>Some common causes of irrigation component failure are:</Typography>
          <ul>
            <li>Physical damage from vehicles, foot traffic, and construction work</li>
            <li>Clogged lines and heads</li>
            <li>General wear and tear on moving parts</li>
            <li>Corrosion</li>
            <li>Excessive stress due to local climate and heaving of the ground during freeze and thaw cycles</li>
          </ul>
          <Typography>
            With all the new technology we will work through our start ups and inspections and will be looking beyond
            routine repairs to identify opportunities to make upgrades to ensure that your systems are running as
            efficiently as possible
          </Typography>
        </>
      }
      images={
        <>
          <Img src={img0} alt="sprinklers" />
          <Img src={img1} alt="sprinklers" />
        </>
      }
    />
  );
}

export default Irrigation;
