/** @param {string} key */
function getValue(key) {
  return process.env[`REACT_APP_${key}`];
}

/** @param {string} key */
function ensureValue(key) {
  const value = getValue(key);

  if (!value) {
    console.error(`The following config is required: ${key}`);
  }

  return value;
}

const env = {
  galleryDomain: ensureValue("GALLERY_DOMAIN"),
  jotformContactId: ensureValue("JOTFORM_CONTACT_ID"),
};

export default env;
