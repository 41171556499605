import { useState } from "react";

import { Box, TextField } from "@mui/material";

/** @type {{
 *   label: string;
 *   prop: keyof IContactInfo;
 *   type?: import("react").InputHTMLAttributes<any>['type'];
 *   autoComplete?: string;
 *   span?: number;
 * }[]} */
const fields = [
  {
    label: "Company Name",
    prop: "companyName",
    autoComplete: "nope",
    span: 2,
  },
  {
    label: "Contact Name",
    prop: "contactName",
    autoComplete: "nope",
    span: 2,
  },
  {
    label: "Street Address",
    prop: "streetAddress",
  },
  {
    label: "City",
    prop: "city",
    span: 2,
  },
  {
    label: "State",
    prop: "state",
    span: 1,
  },
  {
    label: "Zip",
    prop: "zip",
    type: "tel",
    span: 1,
  },
  {
    label: "Email",
    prop: "email",
    type: "email",
    span: 2,
  },
  {
    label: "Phone",
    prop: "phone",
    type: "tel",
    span: 2,
  },
];

/**
 * @param {Object} p
 * @param {(x: any) => any} p.onChange
 */
function ContactInfo(p) {
  const [data, setData] = useState(/** @type {IContactInfo} */ ({}));

  return (
    <Box display="grid" gridAutoFlow="dense" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="1rem">
      {fields.map(item => (
        <TextField
          key={item.prop}
          label={item.label}
          autoComplete={item.autoComplete}
          type={item.type}
          required={true}
          value={data[item.prop] ?? ""}
          onChange={e => {
            const _new = { ...data, [item.prop]: e.target.value };

            setData(_new);
            p.onChange(_new);
          }}
          sx={{
            background: "white",
            gridColumn: { xs: "auto / span 4", sm: `auto / span ${item.span ?? 4}` },
          }}
        />
      ))}
    </Box>
  );
}

export default ContactInfo;
