import { Box } from "@mui/material";

/** @param {ICssGrid} p */
function CssGrid(p) {
  let { itemWidth, ...innerP } = p;

  if (!itemWidth) {
    itemWidth = "25rem";
  }

  return (
    <Box display="grid" gridTemplateColumns={`repeat(auto-fit, minmax(min(100%, ${itemWidth}), 1fr))`} {...innerP}>
      {p.children}
    </Box>
  );
}

export default CssGrid;
