import { Route, Routes } from "react-router-dom";

import About from "@/pages/about/About";
import Contact from "@/pages/contact/Contact";
import Home from "@/pages/home/Home";
import Join from "@/pages/join/Join";
import Commercial from "@/pages/services/commercial/Commercial";
import Residential from "@/pages/services/residential/Residential";
import ThankYou from "@/pages/thankYou/ThankYou";

function _Routes() {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Commercial />} />
      <Route path="/services/commercial" element={<Commercial />} />
      <Route path="/services/residential" element={<Residential />} />
      <Route path="/join" element={<Join />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
}

export default _Routes;
