import { Container } from "@mui/material";

/**
 * MinHeight: Inherit
 * @note This is meant to be used with a parent that has minHeight: fullHeight
 * @param {import("@mui/material").ContainerProps} p
 */
function TallContainer(p) {
  return (
    <Container {...p} sx={{ minHeight: "inherit", ...p.sx }}>
      {p.children}
    </Container>
  );
}

export default TallContainer;
