import { useEffect } from "react";

import { useLocation } from "react-router-dom";

// https://stackoverflow.com/a/61311926

function useScrollEffect() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
      return;
    }

    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) {
        const headerBottom = document.querySelector("header")?.getBoundingClientRect().bottom ?? 0;

        const top = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo(0, top - headerBottom);
      }
    }, 0);
  }, [pathname, hash, key]);
}

export default useScrollEffect;
