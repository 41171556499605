import { Box } from "@mui/material";

import theme from "@/theme";

/**
 * @param {import("@mui/material").BoxProps<"img">} p
 */
function Img(p) {
  return (
    <Box
      component="img"
      width="100%"
      boxShadow={theme.lightBoxShadow}
      {...p}
      sx={{ aspectRatio: "3 / 2", objectFit: "cover", ...p.sx }}
    />
  );
}

export default Img;
