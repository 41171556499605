import { Typography } from "@mui/material";

import Img from "@/comps/Img";

import ServiceDetail from "../serviceDetail/ServiceDetail";
import img0 from "./0.jpg";
import img1 from "./1.jpg";

function Landscaping() {
  return (
    <ServiceDetail
      title="Landscaping"
      description={
        <>
          <Typography>WMV Enterprises provides first class commercial landscape management.</Typography>
          <Typography>
            We fully understand what it takes to design the ideal landscape for each of our clients. Each parcel of land
            is unique, as many people in our area have discovered. This necessitates a great deal of personal attention
            and care. We'll collaborate closely with you to create a personalized, functioning landscape management
            plan.
          </Typography>
        </>
      }
      images={
        <>
          <Img src={img0} alt="sidewalk" />
          <Img src={img1} alt="lawn" />
        </>
      }
    />
  );
}

export default Landscaping;
